/* src/components/styles/HomePage.module.css */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #061620;
}

.hero {
  position: relative;
  width: 100%;
  max-width: 1900px;
  height: 1219px;
  display: flex;
  align-items: center;

  @media (width <= 800px) {
    height: 600px;
  }
}

.heroBackground {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroText h1 {
  position: absolute;
  left: 0;
  top: 35%;
  max-width: 676px;
  padding: 30px;
  color: #061b27;
  text-align: right;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0px 15px 15px 0px;
  opacity: 0.8;
  background: #d9d9d9;

  @media (width <= 800px) {
    font-size: 20px;
  }
}

.content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eee;
}

.mainTextContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px 0;

  @media (width <= 800px) {
    margin: 100px 0;
  }
}

.mainText {
  margin: 0;
  padding: 0 40px;
  max-width: 800px;
  color: #061620;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: italic;
  font-weight: 700;
  line-height: 110.023%; /* 27.506px */
  letter-spacing: 1.25px;
  text-transform: uppercase;

  @media (width <= 800px) {
    font-size: 18px;
  }
}

.icons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 200px;
}

.iconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 235px;
}

.iconItem p {
  color: #1f2d36;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.023%; /* 19.804px */
  text-transform: uppercase;
}

.iconBackground {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.bannerSection {
  background: #05151f;
  height: 564px;
  min-height: 564px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1900px;

  @media (width <= 800px) {
    height: 100%;
    min-height: 564px;
    flex-wrap: wrap;
  }
}

.bannerSectionImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  @media (width <= 900px) {
    min-height: 564px;
  }
}

.bannerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #05151f;
}

.bannerContent p {
  color: #fff;
  max-width: 651px;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 40px */
  letter-spacing: 1.25px;
  text-transform: capitalize;

  @media (width <= 900px) {
    padding: 20px;
    font-size: 18px;
  }
}

.valuesSection {
  margin: 200px 0;
  width: 100%;
}

.valuesSection h2 {
  color: #061620;
  text-align: center;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.023%; /* 38.508px */
  text-transform: uppercase;
  margin-bottom: 200px;
}

.values {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1900px;
}

.valueItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;

  @media (width <= 800px) {
    width: 100%;
  }
}

.valueBackground {
  max-width: 480px;
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (width <= 800px) {
    max-width: 100%;
  }
}

.valueItem p {
  background: #061620;
  padding: 40px 0;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.023%; /* 27.506px */
  text-transform: uppercase;
  margin: 0;
  width: 100%;
}

@media (width <= 500px) {
  & svg {
    display: none;
  }
}
