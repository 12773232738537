/* src/components/styles/SolutionsPage.module.css */
.solutions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solutionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1900px;
  width: 100%;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: #05151f;

  @media (width <= 900px) {
    flex-wrap: wrap;
  }
}

.sectionreverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  background-color: #05151f;

  @media (width <= 900px) {
    min-height: 564px;
    flex-wrap: wrap;
  }
}

.sectionBackground {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 564px;

  &.sectionBackgroundOverlay {
    width: 100%;
    height: 100%;
    position: relative;
    height: 564px;

    &::after {
      z-index: 1;
      opacity: 0.2;
      background-color: #fff;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.sectionText {
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 564px;
}

.sectionTextHidden {
  background: #fff;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 564px;
  max-width: 50%;

  @media (width <= 900px) {
    height: 864px;
    max-width: 100%;
    padding: 20px;
  }

  & .sectionTextHiddenTitle {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    & h2 {
      color: #000;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 30px */
      text-transform: capitalize;
      max-width: 340px;
      margin: 0 0 10px;

      @media (width <= 900px) {
        font-size: 25px;
      }
    }
  }

  & p {
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
    margin: 0;

    @media (width <= 900px) {
      font-size: 18px;
    }
  }

  & .secondButton {
    border-radius: 15px;
    border: 1px solid #061620;
    width: 220px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #061620;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.9px;
    text-transform: capitalize;
    margin: 10px 0 0;
    cursor: pointer;

    & a {
      text-decoration: none;
      color: #061620;
    }
  }
}

.sectionText p {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 40px */
  letter-spacing: 1.25px;
  text-transform: capitalize;
  max-width: 300px;
  margin-bottom: 72px;

  @media (width <= 900px) {
    font-size: 18px;
    padding: 20px;
  }
}

.comingSoonButton {
  background-color: transparent;
  color: #fff;
  border-radius: 15px;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
