/* src/components/styles/Header.module.css */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e;
  color: #fff;
  width: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e1e;
  color: #fff;
  padding: 15px 30px;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1900px;
  width: 100%;
}

.logo img {
  height: 50px;
}

nav {
  display: flex;
  flex-wrap: wrap;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  padding: 15px 30px;
  color: #999;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.023%; /* 22.005px */
  letter-spacing: -0.7px;

  @media (width <= 800px) {
    width: 100%;
    padding: 15px 30px;
  }
}

nav a:hover {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
  padding: 15px 30px;
}

.active {
  background: #00a9dd;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
}
