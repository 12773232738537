body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #eee;
}

* {
  box-sizing: border-box;
}
