/* src/components/styles/ContactPage.module.css */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #060e1b;
}

.hero {
  position: relative;
  width: 100%;
  height: 1024px;
  max-width: 1900px;
  display: flex;
  align-items: center;

  @media (width <= 800px) {
    height: 600px;
  }
}

.heroBackground {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundDecoration {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 150%;
  max-width: 1900px;

  & p {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.023%; /* 22.005px */
    letter-spacing: -0.7px;
    margin-top: 200px;
    margin-bottom: 80px;

    @media (width <= 800px) {
      margin-top: 100px;
    }
  }
}

.content {
  width: 100%;
  max-width: 1000px;
  text-align: center;
  margin-bottom: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  & svg {
    height: 80px;
  }

  @media (width <= 800px) {
    margin-bottom: 100px;
  }
}

.response {
  max-width: 1000px;

  & p {
    max-width: 680px;
    margin: 0;
  }
}

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & label {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.023%; /* 17.604px */
    letter-spacing: -0.56px;
    margin-bottom: 6px;
  }

  & input,
  textarea {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #d9d9d9;
    height: 50px;
    width: 100%;
    padding: 0 0 0 8px;
    font-size: 18px;
  }

  & textarea {
    height: 250px;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 530px;
  width: 100%;
}

.col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.col .input {
  width: 100%;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

& .secondButton {
  border-radius: 15px;
  border: 1px solid #061620;
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #061620;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.9px;
  text-transform: capitalize;
  margin: 80px 0 0;
  cursor: pointer;
}
