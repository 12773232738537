/* src/components/styles/Footer.module.css */
.footer {
  background: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 60px;
  background: #1e1e1e;
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.023%; /* 22.005px */
  letter-spacing: -0.7px;
  max-width: 1900px;
  width: 100%;

  @media (width <= 500px) {
    width: 100%;
    padding: 12px;
  }
}

.column a {
  color: #999;
  text-decoration: none;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.023%; /* 22.005px */
  letter-spacing: -0.7px;
  display: flex;
  align-items: center;

  @media (width <= 500px) {
    font-size: 12px;
  }
}

.column p {
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.023%; /* 22.005px */
  letter-spacing: -0.7px;

  @media (width <= 500px) {
    font-size: 12px;
  }
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
