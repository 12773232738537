/* src/components/styles/AboutUsPage.module.css */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #045569;
}

.hero {
  position: relative;
  width: 100%;
  max-width: 1900px;
  height: 1024px;
  display: flex;
  align-items: center;

  @media (width <= 800px) {
    height: 600px;
  }
}

.heroBackground {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundDecoration {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 150%;
  max-width: 1900px;
}

.content {
  width: 100%;
  max-width: 1000px;
  text-align: center;
  margin: 300px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (width <= 800px) {
    margin: 100px 0;
    padding: 20px;
  }

  & svg {
    height: 80px;
  }

  & p {
    color: #061620;
    text-align: center;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 110.023%; /* 27.506px */
    letter-spacing: 1.25px;
    max-width: 640px;

    & span {
      color: #00a9dd;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 110.023%;
      letter-spacing: 1.25px;
    }
  }
}
